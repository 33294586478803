.alert-main {
  animation: fadeIn ease 0.5s;
  width: 500px;
  margin: auto;
  position: fixed;
  background-color: white;
  min-height: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  top: 20vh;
  left: calc(50vw - 250px);
  border-radius: 10px;
}
@media (max-width: 550px) {
  .alert-main {
    width: 90vw;
    left: 5vw;
 }
}
.alert-header {
  width: 100%;
  padding: 15px;
  font-size: 20px;
  font-weight: 600;
  background: rgb(0 0 0 / 10%);
  position: relative;
  margin-bottom: 20px;
}

.alert-body {
  text-align: left;
  font-size: 17px;
  width: 100%;
  padding: 0 0 0 30px;
  position: relative;
  color: #000 !important;
}

.alert-button {
  width: 90px;
  height: 40px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: white;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  align-items: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
  border-radius: 100px !important;
  color: #fff;
}


.alert-button:hover {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.backdrop {
  animation: fadeIn ease 1s;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
}
.alert-cross-bt{
  position: absolute;
  width: 36px !important;
  height: 36px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -17px;
  top: -17px;
  padding: 0 !important;
  border-radius: 100px !important;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.alert-cross-bt:hover {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.error_alert{
  background-color: rgb(220, 53, 69) !important;
}

.error_alert.btn.btn-primary:focus{
  background-color: rgb(220, 53, 69) !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}


.success_alert{
  background-color: rgb(40, 167, 69) !important;
}

.success_alert.btn.btn-primary:focus{
  background-color: rgb(40, 167, 69) !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.warning_alert{
  background-color: rgb(255, 193, 7) !important;
}

.warning_alert.btn.btn-primary:focus{
  background-color: rgb(255, 193, 7) !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.defaultColor_alert{
  background-color: rgb(51, 51, 51) !important;
}

.defaultColor_alert.btn.btn-primary:focus{
  background-color: rgb(51, 51, 51) !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
 }
  100% {
    opacity: 1;
 }
}
